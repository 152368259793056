import * as React from 'react'
import {useRef} from 'react'
import useIntersectionObserver from '@react-hook/intersection-observer'
import {StaticImage} from 'gatsby-plugin-image'
import {
    videoContainer,
    gridBox,
    firstBlock,
} from './heroArea.module.css'
import {speratorLine, titleHeading, bulletPoint} from '../fiveJBR/form.module.css'

import Slider from "react-slick";
import {firstGrid, gridText} from "../../styles/main.module.css";
import {roundImage} from "./heroArea.module.css";
import image1 from "../../images/AL-KHAIL-ROAD.png";
import image2 from "../../images/MEYDAN-RACECOURSE.png";
import image3 from "../../images/MEYDAN-ONE-MALL-MEYDAN-TOWER.png";
import image4 from "../../images/FESTIVAL-CITY-IKEA-1.png";


const settings = {
    infinite: true,
    slidesToShow: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
        {
            breakpoint: 1008,
            settings: {
                slidesToShow: 1,
            },
        }],
}

const PaymentPlan = ({onFormOpen}) => {
    return <div className={'pb-0'}>

        <h2 id="paymentplan" className={`${titleHeading} font-bold text-lg sm:text-2xl m-auto mb-0 pt-0`}>
            3 Years Payment Plan
        </h2>
        <p className={'text-gray-500 mb-6'}></p>

        <div className="grid grid-cols-1 md:mb-4 md:grid-cols-2 lg:grid-cols-2 gap-4 sm:pb-0 pb-4">
            <div className={`${gridBox}`}>
                <div className={`${firstBlock}`}>
                    <h2>80%</h2>


                </div>
                <div>
                    <h4 className={'text-gray-700'}>
                        Down payment
                    </h4>
                </div>

            </div>
            {/*<div className={`${gridBox}`}>*/}
            {/*    <div className={`${firstBlock}`}>*/}
            {/*        <h2>55%</h2>*/}
            {/*    </div>*/}
            {/*    <div>*/}
            {/*        <h4 className={'text-gray-700'}>*/}
            {/*            Payable during construction*/}
            {/*        </h4>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className={`${gridBox}`}>
                <div className={`${firstBlock}`}>
                    <h2>20%</h2>
                </div>
                <div>
                    <h4 className={'text-gray-700'}>
                        Payable on handover
                    </h4>
                </div>
            </div>
        </div>


        {/*<div className="text-center mt-8">*/}
        {/*    <button*/}
        {/*        style={{flex: 1, alignContent: 'center'}}*/}
        {/*        onClick={() => {*/}
        {/*            onFormOpen('payment')*/}
        {/*            document.getElementById('downloadbrochure').style.display = 'block'*/}
        {/*        }}*/}
        {/*        className={'rounded  text-lg  bg-primary-darkGold py-3 px-4 text-white'}*/}
        {/*        type="submit">*/}
        {/*        <span>Download Payment Plans </span>*/}
        {/*    </button>*/}
        {/*</div>*/}


    </div>
}

const Pricing = ({onFormOpen, brochureName}) => {
    return <div>

        <div>
            <p className={'text-gray-700 mt-4 mb-6'}>
                Malta, a brand-new collection of 4-5BR townhouses in DAMAC Lagoons that is suggestive of the lovely
                southern Mediterranean nation, will enjoy elegance and comfort right in the heart of Dubai.
                The luxurious townhouses in the Malta cluster exude the ideal Mediterranean vibes and are inspired by
                well-preserved honey-stone landmarks and the sparkling beaches of Valletta, allowing you the possibility
                to discover new pathways in this exciting residential neighborhood.
                DAMAC Lagoons' Malta Townhomes, The Mediterranean Island nation of Malta has a lot to offer, from the
                well-preserved and elegant honey stone landmarks to the warm and glistering beach all year long.

            </p>

            <h2 id="paymentplan" className={`${titleHeading} font-bold text-lg sm:text-2xl m-auto mb-0 pt-0`}>
                Lagoons new launch, Luxury standalone townhouses by Damac properties
            </h2>
            <p className={'mb-6'}>

            </p>

            <div>
                <StaticImage
                    srl_gallery_image="true"
                    src={'../../images/damacLagoon/maltaImage.jpg'}
                    alt={'Malta'}
                />

                <h2
                    className={`text-left font-bold text-lg pb-0 sm:text-4xl m-auto mt-4 pt-1`}>Malta</h2>

                <p className={'text-left text-lg mt-4 font-semibold'}>
                    4 & 5 BR Townhouses at DAMAC Lagoons
                </p>
                <h3 className={'text-right sm:text-3xl text-2xl mt-4 text-darkGold'}>
                    AED 1,830,000
                </h3>
                <p className={'border-b-2 border-darkGold text-right w-56 mt-2 ml-auto'}>

                </p>

                <p className={'text-right text-sm mt-4 font-semibold text-darkGold'}>
                    STARTING PRICE
                </p>
            </div>
            <div className={'text-left mt-8'}>
                <button
                    style={{flex: 1, alignContent: 'center', marginRight: '40px', marginBottom: '20px'}}
                    onClick={() => {
                        onFormOpen('payment')
                        document.getElementById('downloadbrochure').style.display = 'block'
                    }}
                    className={'rounded  text-lg  bg-maltaColor py-3 px-4 text-white'}
                    type="submit">
                    <span>Download Payment Plans </span>
                </button>

                <button
                    style={{flex: 1, alignContent: 'center'}}
                    onClick={() => {
                        brochureName('malta')
                        onFormOpen('brochure')

                        document.getElementById('downloadbrochure').style.display = 'block'
                    }}
                    className={'rounded  text-lg  bg-maltaColor py-3 px-4 text-white'}
                    type="submit">
                    <span>Download Brochure </span>
                </button>
            </div>

        </div>


    </div>
}

const LazyIframe = ({url, title}) => {
    const containerRef = useRef()
    const lockRef = useRef(false)
    const {isIntersecting} = useIntersectionObserver(containerRef)
    if (isIntersecting) {
        lockRef.current = true
    }
    return (
        <div
            style={{
                // overflow: 'hidden',
                // paddingTop: '56.25%',
                // position: 'relative',
                width: '100%',
            }}
            ref={containerRef}
        >
            {lockRef.current && (
                <iframe
                    title={title}
                    style={{
                        bottom: 0,
                        height: '100%',
                        left: 0,
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        width: '100%',
                    }}
                    src={url}
                    frameborder="0"
                    allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen="allowfullscreen"
                ></iframe>
            )}
        </div>
    )
}

const FloorPlan = (props) => {
    return <div id={'fplan'}>
        <div>
            <h2 id={'floorplan'}
                className={`${titleHeading}  font-bold text-lg pb-0 sm:text-2xl m-auto mb-0 pt-1`}>Floor Plans</h2>
            <div className={'mb-8'}>
                <Slider {...settings}>
                    <div className={'p-4'}>
                        <StaticImage
                            src={'../../images/damacLagoon/3&4villa.png'}
                            alt={'3 & 4 BR Villa'}
                        />

                    </div>
                    <div className={'p-4'}>
                        <StaticImage
                            src={'../../images/damacLagoon/5villa.png'}
                            alt={'5 BR Villa'}
                        />
                    </div>
                    <div className={'p-4'}>
                        <StaticImage
                            src={'../../images/damacLagoon/7-1villa.png'}
                            alt={'7-1villa'}
                        />
                    </div>
                    <div className={'p-4'}>
                        <StaticImage
                            src={'../../images/damacLagoon/7-2villa.png'}
                            alt={'7-2villa'}
                        /></div>
                </Slider>
            </div>
        </div>
        <div className="text-center mt-8">
            <button
                style={{flex: 1, alignContent: 'center'}}
                onClick={() => {
                    if (props.onFormOpen) {
                        props.onFormOpen('floor')
                    }
                    document.getElementById('downloadbrochure').style.display = 'block'
                }}
                className={'rounded  text-lg  bg-primary-darkGold py-3 px-4 text-white'}
                type="submit">
                <span>Download Floor Plans </span>
            </button>
        </div>
        <p className={`${speratorLine}`}></p>
    </div>
}

const Connectivity = (props) => {
    const [loading, setLoading] = React.useState(false)

    return (<>
        <div className={'container'}>

            <Pricing {...props}/>

            <p className={`${speratorLine}`}></p>

            <PaymentPlan {...props}/>

            <p className={`${speratorLine}`}></p>

            <div>

                <h2 className={`${titleHeading} font-bold text-lg sm:text-2xl m-auto mb-0 pt-0`}>
                    DAMAC Lagoons
                </h2>

                <p className={'pb-0 m-auto text-gray-500 mb-6'}>
                </p>

                <div className={videoContainer}>
                    <LazyIframe url={"https://www.youtube.com/embed/anWXCF0r6PI"} title={"Damac Lagoons"}/>
                </div>


                <p className={`${speratorLine}`}></p>

                <div>
                    <div>
                        <h2 className={`${titleHeading} font-bold text-lg sm:text-2xl m-auto mb-16 pt-0 text-center`}>
                            Amenities
                        </h2>


                    </div>

                    <div
                        className={`grid grid-cols-2  sm:grid-cols-3 md:grid-cols-4 gap-0 sm:gap-y-20 sm:gap-x-12 ${firstGrid}`}>
                        <div className={'flex flex-col items-center'}>
                            <div className={roundImage}><img alt={'c'}
                                                             src={image1}/></div>
                            <p>Beach & Lagoon</p>
                        </div>
                        <div className={'flex flex-col items-center'}>
                            <div className={roundImage}><img alt={'c'}
                                                             src={image2}/></div>
                            <p>Green Community</p>
                        </div>

                        <div className={'flex flex-col items-center'}>
                            <div className={roundImage}><img alt={'c'}
                                                             src={image3}/></div>
                            <p>Outdoor Pool</p>
                        </div>


                        <div className={'flex flex-col items-center'}>
                            <div className={roundImage}><img alt={'c'}
                                                             src={image4}/></div>
                            <p>Kids Play Area</p>
                        </div>


                    </div>

                </div>

            </div>

            <p className={`${speratorLine}`}></p>

            <FloorPlan {...props}/>


        </div>
    </>)
}

export default Connectivity
